<template>
  <Notification :messages="props.flash.message" />
  <slot />
</template>

<script setup>
import {watchEffect} from "vue";
import Notification from "@/Shared/Ui/Notification.vue";
import Notifications from "@/Support/Notifications.js";

const props = defineProps({
    flash: Object
});

const wait = timeout => new Promise(res => setTimeout(() => res(), timeout));

watchEffect(async () => {
    if (!props.flash.message) { return; }

    for (let message of props.flash.message) {
        await wait(200);
        Notifications.add(message);
    }
});
</script>
